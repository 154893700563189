import PrivacyCheckBoxOld from 'components/Form/PrivacyCheckBox/PrivacyCheckBoxOld';
import { REGISTER_FORM_FIELDS, REGISTER_FORM_ID } from 'constants/authentication';
import { useWebConfig } from 'features/configuration/queries';
import useRouter from 'hooks/useRouter';
import { useSelector } from 'hooks/useSelector';
import { useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { ICON_NAMES, ICON_STYLES } from '../../../constants/icon';
import StaticPageTranslations from '../../../i18n/staticPages';
import { getCMSPage, getCmsPageFromState } from '../../../redux/modules/cms';
import { getValidationSchema, validateForm } from '../../../utils/formUtil';
import Button from '../../Button/Button';
import AddressForm from '../../Checkout/CheckoutDeliveryForms/AddressForm/AddressForm';
import Divider from '../../Divider/Divider';
import Flex from '../../Flex/Flex';
import FormGroupInput from '../../Form/FormGroupInput/FormGroupInput';
import ToggleSwitch from '../../Form/ToggleSwitch/ToggleSwitch';
import Icon from '../../Icon/Icon';
import Alert from '../../Layout/Alert/Alert';
import SocialLogins from '../../Login/SocialLogins/SocialLogins';
import PasswordRequirements from '../RegisterPassword/PasswordRequirements/PasswordRequirements';
import RegisterFormValidationSchema, { RegisterValidationSchemaType } from './RegisterFormValidationSchema';

const CMS_PAGE_QUALIFIER = 'privacyPolicy';

interface RegisterFormProps {
  error?: boolean;
  onCancel?: () => void;
  onSubmit: (formValues?: RegisterValidationSchemaType) => void;
}

const RegisterForm = ({ error, onCancel, onSubmit }: RegisterFormProps) => {
  const { formatMessage } = useIntl();
  const router = useRouter();
  const dispatch = useDispatch();
  const { locale } = router;

  const [isSocialRegister] = useState(false);
  const [noAppleEmailPresent] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState('');

  const qualifier = StaticPageTranslations[CMS_PAGE_QUALIFIER][locale];

  const cmsPage = useSelector((state) => qualifier && getCmsPageFromState(state, qualifier, undefined, undefined));

  const { data: webConfig } = useWebConfig();

  const enableB2BRegistration = webConfig?.enableB2BRegistration;

  useEffect(() => {
    if (!cmsPage) {
      dispatch(getCMSPage(qualifier, locale));
    }
  }, []);

  return (
    <Form
      mutators={{
        resetValue: ([field], state, utils) => {
          utils.changeValue(state, field, () => undefined);
        },
        setValue: ([field, value], state, { changeValue }) => {
          changeValue(state, field, () => value);
        },
        updateCountry: (value, state, utils) => {
          utils.changeValue(state, REGISTER_FORM_FIELDS.INVOICE_COUNTRY.name, () => value[0]);
        },
        updatePostalCode: (value, state, utils) => {
          const newLocation = value[0];
          utils.changeValue(state, value[1], () => newLocation || '');
        },
      }}
      onSubmit={onSubmit}
      render={({ form, handleSubmit, pristine, submitting, values }) => (
        <form className={REGISTER_FORM_ID} id={REGISTER_FORM_ID} onSubmit={handleSubmit}>
          <Flex flexDirection="column" gap={200}>
            <div>
              {error && <Alert type="danger">{error}</Alert>}

              {isSocialRegister && (
                <Alert type="success">
                  {formatMessage({
                    id: 'register_complete_social_registration',
                  })}
                </Alert>
              )}

              {/* B2B */}
              {enableB2BRegistration && (
                <ToggleSwitch
                  id={REGISTER_FORM_FIELDS.IS_B2B_CUSTOMER.id}
                  label={formatMessage({
                    id: REGISTER_FORM_FIELDS.IS_B2B_CUSTOMER.label,
                  })}
                  name={REGISTER_FORM_FIELDS.IS_B2B_CUSTOMER.name}
                  onClick={() => {
                    form.mutators.resetValue(REGISTER_FORM_FIELDS.COMPANY_NAME.name);
                    form.mutators.resetValue(REGISTER_FORM_FIELDS.COMPANY_VAT.name);
                  }}
                />
              )}

              {/* Name */}
              <FormGroupInput
                id={REGISTER_FORM_FIELDS.FIRST_NAME.id}
                label={formatMessage({
                  id: REGISTER_FORM_FIELDS.FIRST_NAME.label,
                })}
                name={REGISTER_FORM_FIELDS.FIRST_NAME.name}
              />

              <FormGroupInput
                id={REGISTER_FORM_FIELDS.LAST_NAME.id}
                label={formatMessage({
                  id: REGISTER_FORM_FIELDS.LAST_NAME.label,
                })}
                name={REGISTER_FORM_FIELDS.LAST_NAME.name}
              />

              {/* B2B */}
              {enableB2BRegistration && values[REGISTER_FORM_FIELDS.IS_B2B_CUSTOMER.name] && (
                <>
                  <FormGroupInput
                    id={REGISTER_FORM_FIELDS.COMPANY_NAME.id}
                    label={formatMessage({
                      id: REGISTER_FORM_FIELDS.COMPANY_NAME.label,
                    })}
                    name={REGISTER_FORM_FIELDS.COMPANY_NAME.name}
                  />

                  <FormGroupInput
                    id={REGISTER_FORM_FIELDS.COMPANY_VAT.id}
                    label={formatMessage({
                      id: REGISTER_FORM_FIELDS.COMPANY_VAT.label,
                    })}
                    name={REGISTER_FORM_FIELDS.COMPANY_VAT.name}
                  />

                  <FormGroupInput
                    id={REGISTER_FORM_FIELDS.PHONE.id}
                    label={formatMessage({
                      id: REGISTER_FORM_FIELDS.PHONE.label,
                    })}
                    name={REGISTER_FORM_FIELDS.PHONE.name}
                  />

                  <AddressForm
                    formId={REGISTER_FORM_ID}
                    isInvoice
                    onSubmitPostalcode={form.mutators.updatePostalCode}
                    setAddressFieldValue={form.mutators.setValue}
                    values={values}
                    withTelephone={false}
                    onChangeCountryCallback={form.mutators.updateCountry}
                  />
                </>
              )}

              {/* Email */}
              <FormGroupInput
                disabled={isSocialRegister && !noAppleEmailPresent}
                id={REGISTER_FORM_FIELDS.EMAIL.id}
                label={formatMessage({
                  id: REGISTER_FORM_FIELDS.EMAIL.label,
                })}
                name={REGISTER_FORM_FIELDS.EMAIL.name}
                type={REGISTER_FORM_FIELDS.EMAIL.type}
              />

              {/* Password */}
              {!isSocialRegister && (
                <>
                  <FormGroupInput
                    appendedContent={
                      password?.length > 0 && (
                        <div onClick={() => setShowPassword(!showPassword)} role="button" tabIndex={0}>
                          <Icon type="custom" name={showPassword ? ICON_NAMES.EYE : ICON_NAMES.EYE_SLASH} />
                        </div>
                      )
                    }
                    handleChange={(event) => setPassword(event.target.value)}
                    id={REGISTER_FORM_FIELDS.PASSWORD.id}
                    label={formatMessage({
                      id: REGISTER_FORM_FIELDS.PASSWORD.label,
                    })}
                    name={REGISTER_FORM_FIELDS.PASSWORD.name}
                    showError={false}
                    type={showPassword ? 'text' : 'password'}
                  />

                  <PasswordRequirements password={password} />
                </>
              )}

              {/* Terms and Conditions */}
              <PrivacyCheckBoxOld
                formId={REGISTER_FORM_ID}
                formType="register"
                termsAndConditions={formatMessage({
                  id: 'footer_newsletter_form_accept_terms_and_conditions',
                })}
                termsAndConditionsLink={formatMessage({
                  id: 'footer_newsletter_form_terms_and_conditions_link',
                })}
              />
              {/* Submit & Cancel Buttons */}
              <Flex gap={100}>
                {!!onCancel && (
                  <Button
                    color="secondary"
                    fullwidth
                    icon={ICON_NAMES.ANGLE_LEFT}
                    iconPosition="before"
                    iconStyling={ICON_STYLES.SOLID}
                    onClick={() => {
                      onCancel();
                    }}
                    size="large"
                    text
                  >
                    {formatMessage({
                      id: 'register_form_cancel',
                    })}
                  </Button>
                )}

                <Button disabled={pristine || submitting} fullwidth size="large" type="submit">
                  {formatMessage({
                    id: 'register_form_submit',
                  })}
                </Button>
              </Flex>
            </div>

            {/* Social Logins */}
            {!isSocialRegister && (
              <Flex flexDirection="column" gap={200}>
                <Divider
                  text={formatMessage({
                    id: 'general_or_text',
                  })}
                />
                <SocialLogins />
              </Flex>
            )}
          </Flex>
        </form>
      )}
      validate={validateForm(getValidationSchema(RegisterFormValidationSchema))}
    />
  );
};

export default RegisterForm;
